import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { toParams } from 'libs/utils/url'
import { serverSide } from 'libs/utils/environment'

const eventTarget = new EventTarget()
const CHANGE_EVENT = 'change'

if (!serverSide) {
  const { pushState, replaceState } = window.history

  const onChange = () => eventTarget.dispatchEvent(new CustomEvent(CHANGE_EVENT))

  window.history.pushState = function pushStateOverride(...args) {
    pushState.apply(this, args)
    onChange()
  }

  window.history.replaceState = function replaceStateOverride(...args) {
    replaceState.apply(this, args)
    onChange()
  }
}

const useQueryParams = () => {
  const router = useRouter()

  const [queryParams, setQueryParams] = useState(() => {
    if (serverSide) {
      const search = router.asPath.split('?')[1]?.split('#')[0] || ''

      return toParams(search ? `?${search}` : '')
    }

    return toParams(window.location.search)
  })

  useEffect(() => {
    const callBack = () => {
      setQueryParams(toParams(window.location.search))
    }

    eventTarget.addEventListener(CHANGE_EVENT, callBack)

    return () => eventTarget.removeEventListener(CHANGE_EVENT, callBack)
  }, [])

  return queryParams
}

export default useQueryParams
