'use client'

import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import AdsProvider from 'containers/AdsProvider'

import Advertisement from 'components/Advertisement'
import AdvertisementBlockCheck from 'components/AdvertisementBlockCheck'
import AppBanner from 'components/AppBanner'
import DomainSelectModal from 'components/DomainSelectModal'
import { GoogleAnalyticsTracker } from 'components/Google/Analytics'
import Header from 'components/Header'
import HeaderNoNavigation from 'components/HeaderNoNavigation'
import InAppMessage from 'libs/common/braze/components/InAppMessage'
import MainFooter from 'components/MainFooter'
import PolicyFooter from 'components/PolicyFooter'
import VacationNotification from 'components/VacationNotification'
import GlobalTwoFaModal from 'components/GlobalTwoFAModal'
import ErrorBoundary from 'components/ErrorBoundary'
import FlashMessage from 'components/FlashMessage'

import { AdShape } from 'constants/ads'
import { CatalogDto, CountryLinkDto, LanguageDto } from 'types/dtos'
import { PageId } from 'types/page-id'
import { SearchBarSearchType } from 'constants/search'
import useIsInPage from 'hooks/useIsInPage'
import useSession from 'hooks/useSession'
import usePageId from 'hooks/usePageId'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useSystemConfiguration from 'hooks/useSystemConfiguration'
import { SavedSearchesProvider } from 'components/Header/SavedSearchesProvider'

import { AdsPlacementModel } from 'types/models'

import AdScripts from '../../components/AdScripts'
import { withLayout } from '../../libs/server-utils/hoc'

const policyFooterPages: Array<PageId> = [
  'item-edit',
  'items-new',
  'inbox',
  'inbox-message',
  'settings-security-phone-verification',
  'checkout',
]
const inboxPages: Array<PageId> = ['inbox', 'inbox-message']
const noSpacerBeforeMainFooterPages: Array<PageId> = [
  'pro',
  'about',
  'how-it-works',
  'no-changes-to-taxes',
  'influencer-landing-page',
]
const layoutBreakoutPages: Array<PageId> = [
  'app',
  'checkout',
  'how-it-works',
  'referrals',
  'referrals-registrations',
  'root',
]
const nonWhiteBackgroundPages: Array<PageId> = [
  'checkout',
  'common-text',
  'complaint',
  'complaint-faq-entry',
  'delete-account',
  'feedback',
  'item',
  'item-edit',
  'items-new',
  'member-balance-activation',
  'member-edit-feedback',
  'member-general-change-forgot-password',
  'member-general-change-password',
  'member-general-delete-account',
  'member-new-feedback',
  'member-notifications',
  'member-personalization-brands',
  'member-personalization-sizes-by-category',
  'member-satisfaction-survey',
  'member-settings-new-email',
  'privacy-policy',
  'private-beta-terms-of-use',
  'pro-terms-and-conditions',
  'pro-terms-of-use',
  'request-data-export',
  'settings-account',
  'settings-account-staff',
  'settings-bundle-discount',
  'settings-donations',
  'settings-epr',
  'settings-payments',
  'settings-policies',
  'settings-privacy',
  'settings-profile',
  'settings-security',
  'settings-security-payments-identity',
  'settings-security-payments-identity-mobile',
  'settings-security-phone-verification',
  'settings-security-sessions',
  'settings-shipping',
  'settings-subscriptions',
  'settings-tax-rules',
  'settings-taxpayer',
  'settings-taxpayer-education',
  'settings-taxpayer-report',
  'settings-taxpayer-summary',
  'taxpayers-special-verification-form',
  'users-verification',
  'users-verification-new-email',
  'wallet-balance',
  'wallet-balance-donate',
  'wallet-bank-account',
  'wallet-history',
  'wallet-history-by-year-month',
  'wallet-history-overview',
  'wallet-income-reports',
  'wallet-invoices',
  'wallet-new-payout',
  'wallet-payout',
  'wallet-setup',
]

export type Props = {
  languages: Array<LanguageDto>
  catalogTree: Array<CatalogDto>
  hasNoNavigation: boolean
  domainLinks: Array<CountryLinkDto> | null
  children: ReactNode
  adsPlacements: Array<AdsPlacementModel>
  adsSegments: Record<string, string>
  shouldMockAds: boolean
}

const resolveHeaderSearchType = (pathname: string): SearchBarSearchType => {
  const FIRST_VALUE_AFTER_SLASH_INDEX = 1
  const firstValueAfterSlash = pathname.split('/')[FIRST_VALUE_AFTER_SLASH_INDEX]

  if (firstValueAfterSlash === 'member') return SearchBarSearchType.User
  if (firstValueAfterSlash === 'help') return SearchBarSearchType.Faq

  return SearchBarSearchType.Item
}

function useWhiteBackground() {
  const pageId = usePageId()
  const isUpdatedFeedbackPageEnabled = useFeatureSwitch('updated_feedback_page')

  if (!pageId) return true
  if (pageId === 'feedback') return isUpdatedFeedbackPageEnabled

  return !nonWhiteBackgroundPages.includes(pageId)
}

export const FullPageLayout = ({
  languages,
  catalogTree,
  hasNoNavigation,
  domainLinks,
  children,
  adsPlacements,
  adsSegments,
  shouldMockAds,
}: Props) => {
  const systemConfiguration = useSystemConfiguration()
  const isWebGa4AnalyticsEnabled = useFeatureSwitch('web_ga4_analytics')
  const { isContentOnlyView: isContentOnly } = useSession()
  const router = useRouter()
  const { isWebview } = useSession()
  const isInPolicyFooterPage = useIsInPage(policyFooterPages)
  const isInInboxPage = useIsInPage(inboxPages)
  const isInNoSpacerBeforeMainFooterPage = useIsInPage(noSpacerBeforeMainFooterPages)
  const shouldBreakoutLayout = useIsInPage(layoutBreakoutPages)
  const isWhiteBackground = useWhiteBackground()

  if (!systemConfiguration) return null

  const renderHeader = () => {
    if (isContentOnly) return null

    if (hasNoNavigation) {
      return <HeaderNoNavigation />
    }

    return (
      <Header
        languages={languages}
        catalogTree={catalogTree}
        searchType={resolveHeaderSearchType(router.pathname)}
        impressumUrl={systemConfiguration.urls.impressum}
        isBusinessAccountLinksVisible={systemConfiguration.businessAccountInformationalLinksVisible}
        isOurPlatformVisible={!!systemConfiguration.urls.ourPlatform}
      />
    )
  }

  const renderFooter = () => {
    if (isContentOnly) return null

    if (!isInPolicyFooterPage && !hasNoNavigation) {
      return (
        <>
          {!isInNoSpacerBeforeMainFooterPage && <Spacer size={Spacer.Size.X5Large} />}
          <div className="u-ui-margin-top-auto">
            <MainFooter
              isBusinessInfoLinksEnabled={
                systemConfiguration.businessAccountInformationalLinksVisible
              }
              isDigitalLawEnabled={!!systemConfiguration.urls.ourPlatform}
              onlineComplaintUrl={systemConfiguration.urls.onlineComplaints}
            />
          </div>
        </>
      )
    }

    return (
      <PolicyFooter
        isDigitalLawEnabled={!!systemConfiguration.urls.ourPlatform}
        isBusinessInfoLinksEnabled={systemConfiguration.businessAccountInformationalLinksVisible}
        onlineComplaintUrl={systemConfiguration.urls.onlineComplaints}
        isStatic={isInInboxPage}
        noDivider={isInInboxPage}
      />
    )
  }

  return (
    <AdsProvider placements={adsPlacements} segments={adsSegments} shouldMockAds={shouldMockAds}>
      {/*
        It is important to keep `AdScripts` above
        `Advertisement` component to initialize various
        third party scripts like googletag or etc.
        that are necessary for `Advertisement` component
        to actually render an advertisement.
      */}
      <AdScripts />

      <div
        className={classNames('fullpage-layout is-wide', {
          'u-background-white': isWhiteBackground,
        })}
      >
        <InAppMessage />
        {domainLinks && <DomainSelectModal domainLinks={domainLinks} />}

        <SavedSearchesProvider>
          {renderHeader()}

          <main className="site">
            <div className="site-wrapper">
              <ErrorBoundary FallbackComponent={ErrorBoundary.LayoutError}>
                <Advertisement shape={AdShape.Leaderboard} id="ad-leaderboard" />

                <div className="site-content">
                  <div className={classNames({ container: !shouldBreakoutLayout })}>
                    <div className={classNames({ container: shouldBreakoutLayout })}>
                      {!isWebview && <VacationNotification />}
                    </div>

                    <div
                      className={classNames('row u-position-relative', {
                        'u-margin-horizontal-none': shouldBreakoutLayout,
                      })}
                    >
                      <div
                        id="content"
                        className={classNames('grid12', {
                          'u-padding-horizontal-none': shouldBreakoutLayout,
                        })}
                      >
                        <div className="body-content">
                          <FlashMessage />
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <AppBanner />
                <AdvertisementBlockCheck />
                {isWebGa4AnalyticsEnabled && <GoogleAnalyticsTracker />}
              </ErrorBoundary>
            </div>
          </main>
        </SavedSearchesProvider>

        {renderFooter()}
        <GlobalTwoFaModal />
      </div>
    </AdsProvider>
  )
}

export default withLayout<Props>(FullPageLayout)
